module.exports = [{
      plugin: require('C:/Users/wills/code/gatsby/2020-blog/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('C:/Users/wills/code/gatsby/2020-blog/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144234550-1"},
    },{
      plugin: require('C:/Users/wills/code/gatsby/2020-blog/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/wills/code/gatsby/2020-blog/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('C:/Users/wills/code/gatsby/2020-blog/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
